import React from "react";
import { useState } from 'react'
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import apiAgendaNaMao from "../../services/api";
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import {
    Container,
    ContainerItens,
    Div,
    ContainerNavModal,


} from "./styles"

Modal.setAppElement("#root");

export function ModalGestao(i) {

    const [openModalClosed, setOpenModalClosed] = useState(false)

    const {
        register,
        handleSubmit
    } = useForm()

    const onSubmit = async data => {

        try {
            const updateContaFormatData = new FormData()

            updateContaFormatData.append('name', data.name)
            updateContaFormatData.append('tel', data.tel)
            updateContaFormatData.append('estabelecimento', data.estabelecimento)
            updateContaFormatData.append('status', data.status)
            updateContaFormatData.append('plano', data.plano)

            await toast.promise(apiAgendaNaMao.put(`users/${i.itens.row.id}`, updateContaFormatData), {
                pending: 'Atualizando dados',
                success: `Dados atualizados com sucesso!`,
            })
            // window.location.reload()
            closeModal()

        } catch (err) {
            const response = (err.response.data)
            toast.error(response, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    //função para quando for aciosado vai abrir a modal
    function openModal() {
        setOpenModalClosed(true)
    }

    //função para quando for aciosado vai fechar a modal
    function closeModal() {
        setOpenModalClosed(false)
    }

    const deleteContaSubmit = async (i) => {
        const id = i.itens.row.id

        try{
      
            if(window.confirm(`Tem certeza que deseja excluir essa conta e todos seus dados?`)){
              await toast.promise(apiAgendaNaMao.delete(`/HorariosDeleteAll/${id}`),{
                pending: 'Excluindo dados',
                success: `Dados Excluídos com sucesso!`,
              }) 
              window.location.reload()
            } 
          }catch(err){
            
            const response = (err.response.data)
            toast.error(response, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
    }


    return (

        <Container>
            <Div>
                <button  onClick={openModal}><EditIcon /></button>
            </Div>

            <Modal
                isOpen={openModalClosed}
                onRequestClose={closeModal}
                contentLabel="exemplo modal"
                overlayClassName="modal-overlay"
                className="modal-content"
            >
                <ContainerItens>

                    <ContainerNavModal>
                        <label id="nameHead">Editar informações</label>
                        <button id="buttonExit" onClick={closeModal}>X</button>
                    </ContainerNavModal>

                    <div className="itens">
                        <form onSubmit={handleSubmit(onSubmit)} method="put" >
                            <div>
                                <p>Nome:</p>
                                <input {...register("name")} defaultValue={i.itens.row.name} />
                                <p>Tel:</p>
                                <input {...register("tel")} defaultValue={i.itens.row.tel} />
                                <p>Estabelecimento:</p>
                                <input {...register("estabelecimento")} defaultValue={i.itens.row.estabelecimento} />
                            </div>
                            <div>
                                <p>Status:</p>
                                <select {...register("status")} defaultValue={i.itens.row.status}>
                                    <option value={'Ativado'}>Ativado</option>
                                    <option value={'Desativado'}>Desativado</option>
                                </select>

                                <p>Plano:</p>
                                <select {...register("plano")} defaultValue={i.itens.row.plano}>
                                    <option value={'Teste grátis'}>Teste grátis</option>
                                    <option value={'Plano Pro'}>Plano Pro</option>
                                    <option value={'Plano Básico'}>Plano Básico</option>
                                </select>

                            </div>

                            <div className="divButtonAtualizar">
                                <button type="submit">Atualizar</button>
                            </div>
                        </form>
                       
                        <button onClick={() => deleteContaSubmit(i)} className="delete">
                            <DeleteIcon style={{color: 'red'}} />
                            <p>Deletar conta</p>
                        </button>
                    </div>




                </ContainerItens>

            </Modal>

        </Container>
    )

}