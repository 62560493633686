import styled from "styled-components";


export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;

`

export const ContainerHeader = styled.main`
    width: 100vw;
    height: 10vh;
    background-color: #0000CD;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .divAvatar{
        position: relative;
        top: 50px;
        display: flex;
        align-items: center;
        flex-direction: column;
    
        h2{
            margin-top: 4px;
            font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
            font-size: 15px;
        }

        .avatar{
            display: flex;
            align-items: center;
            width: 60px;
            height: 60px;
        }
        
    }

`


export const ContainerItens = styled.main`

  //  background-color: green;
    display: flex;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    

    .DivbuscarService{
        margin-top: 80px;
        width: 100vw;
        height: 10vw;
        display: flex;
        align-items: center;
        justify-content: center;
  
        input{
            width: 200px;
            height: 30px;
            border-radius: 30px;
            font-size: 12px;
            padding-left: 10px;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
        }
    }

    .listTelas{
        margin-top: 20px;
        margin-bottom: 20px;
        width: 88vw;
        display: flex;
        flex-direction: column;


        .divSerive{
            display: grid;
            grid-template-columns: 1px 90% 10%;
            border: 1px solid #e0e0e0;
            box-shadow: 0 4px 4px -2px #989898;
            -webkit-box-shadow: 0 5px 8px -4px rgb(133, 168, 228);
            height: 90px;
            width: 88vw;
            margin-top: 10px;
            border-radius: 10px;
            margin-bottom: 10px;
            
            &:hover{
                background-color: #DCDCDC;
            }

            .divServiceInfor{
                display: flex;
                align-items: baseline;
                justify-content: center;
                flex-direction: column;

                #nameSercice{
                    margin-left: 6px;
                    margin-top: 3px;
                    margin-bottom: 2px;
                    font-size: 14px;
                    color: black;
                    font-family: sans-serif;
                    font-weight: bold;
                }

                #price{
                        font-size: 12px;
                        color: #808080;
                        font-family: sans-serif;
                        display: flex;
                        align-items: center;
                }

                #timeService{
                    font-size: 12px;
                    color: #808080;
                    font-family: sans-serif;
                    display: flex;
                    align-items: center;
                }
            }

            #divIconsSeta{
                display: flex;
                align-items: center;
            }
        }

        .divOpcaoServicePacote{
            display: flex;
            justify-content: space-between;
            align-items: center;
          

            .divButtonOpcao{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #6495ED;
                width: 90px;
                height: 28px;
                border: 2px solid #000080;
                border-radius: 20px;
                button{
                    border-radius: 20px;
                    width: 75px;
                    height: 21px;
                    border: 1px solid black;
                }
            }
        }
    }


    .CalendarioTabela{

        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 10px;

        .divMesButtons{
            display: flex;
            margin-top: 10px;
            button{
                background-color: transparent;
                border: none;
            }
        }
        .divCalendario{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            width: 79vw;
            
            .caixaData{
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                width: 15vw;
                height: 45px;
                margin-top: 12px;
            }

            button{
                width: 60px;
                height: 40px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                border-radius: 10px;
                background-color: 	#DCDCDC;
                border: none;
                font-size: 13px;
                color: black;
                gap: 5px;
            }

            .barraAgendamento{
                width: 50px;
                height: 6px;
                border-radius: 10px;
                background-color: #ccc;

                div{
                    height: 100%;
                    border-radius: 10px;
                    background-color: #222;
                }
            }
        }
    }


    .divProfissional{
        margin-top: 10px;
        display: flex;
        align-items: center;
        flex-direction: row;
        overflow: scroll;

        .buttonAvatarProfissional{
            background-color: transparent;
            border: none;
            margin: 10px;
            display: flex;
            align-items: center;
            flex-direction: column;
            p{
                font-size: 15px;
                color: black;
                font-weight: bold;
                margin-top: 5px;
            }

            &:active{
                background-color: orange;
                border-radius: 20px;
            }
        }
    }


    .divHours{
        display: grid;

        @media(min-width: 180px){
            grid-template-columns: 100px 100px 100px;
        }

        @media(min-width: 300px){
            grid-template-columns:  100px 100px 100px ;
        }

        @media(min-width: 500px){
            grid-template-columns: 100px 100px 100px 100px;
        }

        @media(min-width: 600px){
            grid-template-columns: 100px 100px 100px 100px;
        }

        @media(min-width: 700px){
            grid-template-columns:  150px 150px 150px  150px 150px;
        }

        @media(min-width: 800px){
            grid-template-columns: 150px 150px 150px  150px 150px;
        }

        @media(min-width: 1000px){
            grid-template-columns: 150px 150px 150px  150px 150px 150px;
        }

        .divListHours{
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            margin-left: 10px;

            @media(min-width: 414px){
                margin-left: 30px;
                gap: 20px;
                margin-right: 20px;
            }

            button{
                width: 75px;
                height: 30px;
                border: none;
                border-radius: 10px;
                color: black;
                font-size: 14px;
                background-color: #6666;

                &:active{
                    background-color: orange;
                    border-radius: 20px;
                }
            }
        }
    }

    .descricaoResumo{
        margin-top: 70px;
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        p{
            color: black;
        }
        hr{
            width: 20vw;
            border: 0.5px solid black;
        }
    }

    .divTextResumo{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 20px;
        hr{
            width: 50vw;
            border: 0.5px solid black;
        }

        #divComanda{
            display: grid;
            grid-template-columns: 1fr 230px 30px;
            border-radius: 5px;
            height: auto;
            -webkit-box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
            -moz-box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
            box-shadow: 0px 3px 5px 0px rgba(184,175,184,1);
            margin-bottom: 10px;

            button{
                border: none;
                cursor: pointer;

                &:hover{
                    color: red;
                }
             }
        
            #divMesDiaHora{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 60px;
                gap: 5px;
                background-color: #DCDCDC;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;

                h2{
                    color: black;
                    font-size: 17px;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                }

                h4{
                    color: black;
                    font-size: 15px;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                }

                h5{
                    color: black;
                    font-size: 13px;
                    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
                }
            }

            #divServiceNamePrice{
                display: flex;
                flex-direction: column;
                justify-content: left;
                padding-left: 10px;
                gap: 10px;


                p{
                    font-size: 13px;
                    font-family: Arial, Helvetica, sans-serif;
                }
            }
        }
    }


    .divMaisService{
        display: flex;
        align-items: center;
        margin-top: 10px;
        width: 325px;

        button{
            display: flex;
            width: 325px;
            height: 30px;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            border-radius: 5px;
            background-color: white;
            border: 1.5px solid green;
            padding: 3px;
            &:hover{
            color: green;
            }
        }
    }


    .divConfirmeAgendamento{
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        form{
            gap: 1px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 80%;
        
            label{
                font-size: 12px;
                margin-top: 7px;
                font-weight: bold;
                color: black;
            }

            .name{
                font-size: 15px;
                padding: 5px;
                width: 100%;
                height: 35px;
            }

            .tel{
                font-size: 15px;
                padding: 5px;
                width: 100%;
                height: 35px;
            }

            span{
                font-size: 10px;
                color: red;
            }

            .ConfirmeAgendamento{
                margin-top: 20px;
                padding: 2px;
                border-radius: 5px;
                background-color: green;
                color: white;
                height: 30px;
            }
        }
    }

`        

export const ContainerItensTelaCliente = styled.main`
    display: flex;
    align-items: center;
    margin: 10px;
    flex-direction: column;

    .divAgendamentosClientes{
        margin-top: 80px;
        

        .divFilterAgendamentos{
            display: flex;
            margin: 10px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 5px;

            input{
                width: 300px;
                margin-top: 10px;
                height: 30px;
                padding: 2px;
                font-size: 15px;
            }

            .divButtons{
                display: flex;
                justify-content: space-between;
                gap: 20px;
                margin-bottom: 10px;

                button{
                    border: none;
                    width: 300px;
                    height: 25px;
                    margin-top: 6px;
                    border-radius: 3px;
                    background: #6666;
                    font-size: 12px;
                    color: black;
                    font-weight: bold;
                    cursor: pointer;
                    font-family: Arial, Helvetica, sans-serif;

                    &:hover{
                    background-color: orange;
                    }

                    &:active{
                        background-color: orange;
                    }
                }
            }
        }
    }


    .listAgendamentos{
        margin-top: 20px;
        width: 90%;
        overflow: scroll;
        height: 200px;

        .divListAgendamento{
            margin: 10px;
            background-color: #6666;
        }
    }
`


