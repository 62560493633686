import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import LoginIcon from '@mui/icons-material/Login';
import CheckIcon from '@mui/icons-material/Check';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination} from 'swiper/modules';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import {
    ContainerPrincipal,
    Header,
    Main,
    ImgAppStore,
    ImgPlayStore,
    ImgLogoHeader,
    MainSobre,
    ImgFuncionalidade,
    MainPlanos,
    Footer,
    ImgFuncionalidadePrecificacao,
    DivSlid,
    SlidTitle,
    DisButton
} from './styles'

import AppStore from '../../assets/AppStorepng.png'
import PlayStore from '../../assets/googleplaypng.png'
import logo from '../../assets/Agenda logo png.png'
import logoBranca from '../../assets/Agenda logoBranco png.png'

import imgTelaAgenda from '../../assets/telaAgenda.png'
import imgTelaRelatorio from '../../assets/telaRelatorio.png'
import imgTelaClientes from '../../assets/telaClientes.png'
import imgTelaLink from '../../assets/telaLink.png'

import imgSlide01 from '../../assets/1.png'
import imgSlide02 from '../../assets/2.png'
import imgSlide03 from '../../assets/3.png'
import imgSlide04 from '../../assets/4.png'

import imgSlide0Menor1 from '../../assets/menor1.png'
import imgSlideMenor02 from '../../assets/menor2.png'
import imgSlideMenor03 from '../../assets/menor3.png'
import imgSlideMenor04 from '../../assets/menor4.png'

export function Home() {

    const el = useRef()
    const tl = useRef()
    const [screm, setScrem] = useState('mensal')
    const [buttonActive, setButtonActive] = useState('orange')
    const [slideTamanho, setSlideTamanho] = useState('grande')
    const [indexItem, setIndexItem] = useState(1)

    useLayoutEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.to(".container-inicio", {
            x: 0,
            opacity: 1,
            scrollTrigger: {
                trigger: ".main1",
                end: "bottom 320px",
                //markers: true

            }
        })

        return () => {
            gsap.killTweensOf(".container-inicio")
        }
    }, [])

    useLayoutEffect(() => {
        gsap.registerEffect(ScrollTrigger);

        gsap.context(() => {
            tl.current = gsap.timeline({
                scrollTrigger: {
                    trigger: "#itens",
                    scrub: true,
                    //markers: true,
                    start: "top 97%",
                    end: "bottom 100%"
                }
            })
                .fromTo(".div-carrosel-funcionalidades01", {
                    opacity: 0,
                    y: 160,

                }, {
                    opacity: 1,
                    y: 0
                })
                .fromTo(".div-carrosel-funcionalidades02", {
                    opacity: 0,
                    y: 160,

                }, {
                    opacity: 1,
                    y: 0
                })
                .fromTo(".div-carrosel-funcionalidades03", {
                    opacity: 0,
                    y: 160,

                }, {
                    opacity: 1,
                    y: 0
                })
                .fromTo(".div-carrosel-funcionalidades04", {
                    opacity: 0,
                    y: 160,

                }, {
                    opacity: 1,
                    y: 0
                })
                .fromTo(".div-carrosel-funcionalidades05", {
                    opacity: 0,
                    y: 160,

                }, {
                    opacity: 1,
                    y: 0
                })

        }, el)


        return () => {
            gsap.killTweensOf("#itens")
        }
    }, [])

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 500) {
                setSlideTamanho('pequeno')
            } else {
                setSlideTamanho('grande')
            }
        }
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    const data = [
        { id: '1', image: imgSlide01 },
        { id: '2', image: imgSlide02 },
        { id: '3', image: imgSlide03 },
        { id: '4', image: imgSlide04 },
    ]

    const dataMenor = [
        { id: '1', image: imgSlide0Menor1 },
        { id: '2', image: imgSlideMenor02 },
        { id: '3', image: imgSlideMenor03 },
        { id: '4', image: imgSlideMenor04 },
    ]

    return (
        <>
            <ContainerPrincipal >
                <Header >
                    <div className="logo">
                        <ImgLogoHeader src={logo} />
                    </div>

                    <div className="menu">
                        <nav className="nav-menu">
                            <a href="#main1">INÍCIO</a>
                            <a href="#main2">FUNCIONALIDADES</a>
                            <a href="#main3">PLANOS</a>
                            <a href="#main4">CONTATO</a>
                        </nav>
                    </div>

                    <div className="login">
                        <LoginIcon />
                        <a href="https://agendanamao.com/login">LOGIN</a>
                    </div>
                </Header>
            </ContainerPrincipal>

            <Main className="main1" id="main1">
                <div className="container-inicio">
                    <div className="conteudo-inicio">
                        <div className="titulo-inicio">
                            <h1 className="titulo-agendanamao">AgendaNaMão</h1>
                        </div>
                        <div className="paragrafo-inicio" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
                            <p className="titulo-descricao">Nunca foi tão fácil receber Agendamentos online e Gerenciar seu espaço.</p>
                            <p className="titulo-baixeAgora">Baixe e experimente grátis por 7 dias.</p>
                        </div>
                    </div>

                    <div className='div-baixarApp'>
                        <a rel="noreferrer" target="_blank" href="https://apps.apple.com/br/app/agendanamão/id6738929372">
                            <ImgAppStore src={AppStore} />
                        </a>
                        <a rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=and.agendanamao&hl=pt_BR">
                            <ImgPlayStore src={PlayStore} />
                        </a>
                    </div>
                </div>
            </Main>
            
            <SlidTitle>
                {indexItem === 0 ?

                    <div className="titleSlide">
                        <h1>Gestão descomplicada</h1>
                        <p>Administre seu espaço em qualquer lugar sem nenhuma complicação.</p>
                    </div>

                    : indexItem === 1 ?

                        <div className="titleSlide">
                            <h1>Link de agendamento</h1>
                            <p>Seu cliente realiza agendamento em minutos sem baixar app e sem cadastro.</p>
                        </div>

                        : indexItem === 2 ?
                            <div className="titleSlide">
                                <h1>Notificações e lembretes</h1>
                                <p>Alertas de novos agendamentos e envio de lembretes automáticos e semi-automáticos no whatsapp.</p>
                            </div>
                        : indexItem === 3 ?
                            <div className="titleSlide">
                                <h1>Agenda online para seu salão</h1>
                                <p>Agendamento 24h, gestão da agenda, comandas, financeiro e muito mais.</p>
                            </div>
                        : ''
                }

            </SlidTitle>
            <DivSlid >
                {slideTamanho === 'grande' ?
                    <>
                        <Swiper
                            modules={[Navigation, Pagination]}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            centeredSlides
                            onSlideChange={(i) => setIndexItem(i.activeIndex)}
                            grabCursor
                            speed={500}
                            initialSlide={1}
                            effect="coverflow"
                        >
                            {data.map(i => (
                                <SwiperSlide key={i.id} >
                                    <img alt="slider" className="slide-item" src={i.image} />
                                </SwiperSlide>
                            ))}

                        </Swiper>

                    </>
                    :
                    <>

                        <Swiper
                            modules={[Navigation, Pagination]}
                            slidesPerView={1}
                            navigation
                            pagination={{ clickable: true }}
                            onSlideChange={(i) => setIndexItem(i.activeIndex)}
                            centeredSlides
                            grabCursor
                            speed={500}

                            initialSlide={1}
                            effect="coverflow"

                        >
                            {dataMenor.map(i => (
                                <SwiperSlide key={i.id}>
                                    <img alt="slider" className="slide-itemMenor" src={i.image} />
                                </SwiperSlide>
                            ))}

                        </Swiper>

                    </>
                }

            </DivSlid>
            <DisButton>
                <a href={`https://wa.me//5581992959030?text=Olá, gostaria de testar 7 dias grátis.`} rel="noreferrer" target="_blank">
                    <button>Experimente grátis por 7 dias</button>
                </a>
            </DisButton>


            <MainSobre className="main2" id="main2" ref={el}>
                <div className="div-titulo-inicial-MainSobre">
                    <p className="titulo-principal-mainSobre">O sistema ideal para executar suas Atividades.</p>
                    <p className="titulo-secundario-mainSobre">Com o AgendaNaMão, você otimiza seu tempo e descomplica a gestão do seu espaço.</p>
                </div>

                <div className="div-carrosel-funcionalidades">

                    <div id="itens" className="div-carrosel-funcionalidades01">
                        <ImgFuncionalidade src={imgTelaAgenda} />
                        <p className="titulo-Tela">Agendamentos</p>
                        <p className="descricao-funcionalidades">Tenha total controle da sua agenda na palma da sua mão.</p>
                    </div>

                    <div id="itens" className="div-carrosel-funcionalidades02">
                        <ImgFuncionalidade src={imgTelaRelatorio} />
                        <p className="titulo-Tela">Relatório financeiro</p>
                        <p className="descricao-funcionalidades">Acompanhe de perto os resultados do seu negócio e otimize a sua saúde financeira e dos seus profissionais.</p>
                    </div>

                    <div id="itens" className="div-carrosel-funcionalidades03">
                        <ImgFuncionalidade src={imgTelaClientes} />
                        <p className="titulo-Tela">Cadastro de clientes</p>
                        <p className="descricao-funcionalidades">Construa um relacionamento com seus clientes e aumente seu faturamento.</p>
                    </div>

                    <div id="itens" className="div-carrosel-funcionalidades04">
                        <ImgFuncionalidadePrecificacao src={imgTelaLink} />
                        <p className="titulo-Tela">Link agendamento</p>
                        <p className="descricao-funcionalidades">Seus clientes realizam agendamentos sem cadastro através do seu link personalizável</p>
                    </div>

                    <div id="itens" className="div-carrosel-funcionalidades05">
                        
                    </div>

                </div>
            </MainSobre>

            <MainPlanos className="main3" id="main3">
                <div className="plano-preco">
                    <h1>Planos e Valores</h1>
                    <p className="descricao-planos">Conheça nossos planos e escolha o melhor de acordo com o seu negócio. </p>
                </div>

                <div className="button-mes-ano">
                    {screm === 'mensal' ? <button style={{ backgroundColor: buttonActive }} onClick={() => setScrem('mensal')} className="mensal">Mensal</button> : <button onClick={() => setScrem('mensal')} className="mensal">Mensal</button>}
                    {screm === 'anual' ? <button style={{ backgroundColor: buttonActive }} onClick={() => setScrem('anual')} className="anual">Anual <p className="anual-off">24% off</p></button> : <button onClick={() => setScrem('anual')} className="anual">Anual <p className="anual-off">24% off</p></button>}

                </div>
                {screm === 'mensal' && (
                    <div className="planos">

                        <div className="plano-basico">
                            <h3>Plano básico</h3>
                            <p className="descricao-plano">Ideal para negócios que possuem um profissional.</p>
                            <p className="preco-mes">R$19,90/mês</p>

                            <hr></hr>

                            <div className="div-descricao-plano">
                                <p>  <CheckIcon style={{ color: 'green' }} />Cadastro limitado de profissionais</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Produto/Estoque</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Relatório financeiro</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Agendamentos limitado</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Whatsapp semi-automático</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Caixa/comanda </p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Cadastro de clientes</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> E muito mais...</p>
                            </div>

                            <div className="div-button-adesao">
                                <a href={`https://wa.me//5581992959030?text=Olá, gostaria de mais informações sobre o plano Mensal básico.`} rel="noreferrer" target="_blank">
                                    <button >Saiba mais <WhatsAppIcon /> </button>
                                </a>

                            </div>
                        </div>

                        <div className="plano-pro">
                            <h3>Plano pro</h3>
                            <p className="descricao-plano">Ideal para negócios que possuem mais de um profissional.</p>
                            <p className="preco-mes">R$37,90/mês</p>

                            <hr></hr>

                            <div className="div-descricao-plano">
                                <p>  <CheckIcon style={{ color: 'green' }} /> Até 03 profissionais</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Administre sua agenda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Relatório financeiro</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Produto/Estoque</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Caixa/comanda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Lembretes automáticos (Whatsapp)</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Cadastro de clientes</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> E muito mais...</p>
                            </div>

                            <div className="div-button-adesao">
                                <a href={`https://wa.me//5581992959030?text=Olá, gostaria de mais informações sobre o plano Mensal Pro.`} rel="noreferrer" target="_blank">
                                    <button>Saiba mais <WhatsAppIcon /> </button>
                                </a>

                            </div>
                        </div>

                        <div className="plano-pro">
                            <h3>Plano master</h3>
                            <p className="descricao-plano">Ideal para negócios que possuem mais de um profissional.</p>
                            <p className="preco-mes">R$49,90/mês</p>

                            <hr></hr>

                            <div className="div-descricao-plano">
                                <p>  <CheckIcon style={{ color: 'green' }} /> Até 10 profissionais</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Administre sua agenda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Relatório financeiro</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Produto/Estoque</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Caixa/comanda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Lembretes automáticos (Whatsapp)</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Cadastro de clientes</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> E muito mais...</p>
                            </div>

                            <div className="div-button-adesao">
                                <a href={`https://wa.me//5581992959030?text=Olá, gostaria de mais informações sobre o plano Mensal master.`} rel="noreferrer" target="_blank">
                                    <button>Saiba mais <WhatsAppIcon /> </button>
                                </a>

                            </div>
                        </div>

                    </div>
                )}

                {screm === 'anual' && (
                    <div className="planos">

                        <div className="plano-basico">
                            <h3>Plano básico </h3>
                            <p className="descricao-plano">Ideal para negócios que possuem um profissional.</p>
                            <p className="preco-mes">R$180,00/ano </p>
                            <p className="preco-mes-reducao"> <strike>R$238,00/ano</strike></p>
                            <p className="economia">Pague de uma só vez e economize R$ 58,00 </p>

                            <hr></hr>

                            <div className="div-descricao-plano">
                                <p>  <CheckIcon style={{ color: 'green' }} />Cadastro limitado de profissionais</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Produto/Estoque</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Relatório financeiro</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Agendamentos limitado</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Whatsapp semi-automático</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Caixa/comanda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Cadastro de clientes</p>
                            </div>

                            <div className="div-button-adesao">
                                <a href={`https://wa.me//5581992959030?text=Olá, gostaria de me assinar no plano Anual básico.`} rel="noreferrer" target="_blank">
                                    <button>Teste grátis por 7 dias</button>
                                </a>

                            </div>
                        </div>

                        <div className="plano-pro">
                            <h3>Plano pro</h3>

                            <p className="descricao-plano">Ideal para negócios que possuem mais de um profissional.</p>

                            <p className="preco-mes">R$345,00/ano </p>
                            <p className="preco-mes-reducao"> <strike>R$454,80/ano</strike></p>
                            <p className="economia">Pague de uma só vez e economize R$ 109,80 </p>
                            <hr></hr>

                            <div className="div-descricao-plano">
                                <p>  <CheckIcon style={{ color: 'green' }} /> Até 03 profissionais</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Administre sua agenda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Relatório financeiro</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Produto/Estoque </p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Caixa/comanda</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Lembretes automáticos (Whatsapp)</p>
                                <p>  <CheckIcon style={{ color: 'green' }} /> Cadastro de clientes</p>
                            </div>

                            <div className="div-button-adesao">
                                <a href={`https://wa.me//5581992959030?text=Olá, gostaria de me assinar no plano Anual Pro.`} rel="noreferrer" target="_blank">
                                    <button>Teste grátis por 7 dias</button>
                                </a>

                            </div>
                        </div>

                    </div>
                )}
            </MainPlanos>

            <Footer className="main4" id="main4">
                <div className="container-footer">

                    <div className="footer-column-logo-column">
                        <ImgLogoHeader src={logoBranca} />
                    </div>

                    <div className="footer-column-infor-column">
                        <h2>Contato / Suporte</h2>
                        <p><PhoneAndroidIcon /> 55 81 99295-9030</p>
                        <p><EmailIcon /> agendanamaooficial@gmail.com</p>
                    </div>

                    <div className="footer-column-social-column">
                        <p>Baixe nosso app</p>
                        <a rel="noreferrer" target="_blank" href="https://apps.apple.com/br/app/agendanamão/id6738929372">
                            <ImgAppStore src={AppStore} />
                        </a>
                        <a rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=and.agendanamao&hl=pt_BR">
                            <ImgPlayStore src={PlayStore} />
                        </a>

                    </div>

                </div>
                <br />
                <hr></hr>
                <br />
                <div className="footer-column-credits-column">
                    <p>&copy; 2024 Todos os direitos reservados. </p>
                    <p> Desenvolvido por Alex Silva.</p>
                    <a href="https://agendanamao.com/politica-privacidade" rel="noreferrer" target="_blank">
                        <p>Política de privacidade</p>
                    </a>
                </div>
                <br />
            </Footer>
        </>
    )

}