import React from 'react';
import ReactDOM from 'react-dom/client';
import AppProvider from './hooks';
import { ToastContainer } from 'react-toastify';
import GlobalStyles from './styles/globalStyles';
import Routes from './routes/routes'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  <AppProvider>
   <Routes/>
  </AppProvider>
  <ToastContainer/>
 <GlobalStyles/>
  </>,
);

