import React, {useState, useEffect, useMemo} from "react";
import GroupsIcon from '@mui/icons-material/Groups';
import apiAgendaNaMao from "../../../services/api";
import moment from "moment";
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { ptBR } from '@mui/x-data-grid/locales';
import { ModalGestao } from "../../../components/ModalGestao";
import { ModalNotifications } from "../../../components/ModalNotifications";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import clsx from 'clsx';

import {Container, ContainerMain01,ContainerMenu,ContainerTable} from "./styles"


export function GestaoSistema(){

    const [users, setUsers] = useState([])
    const [listTokens, setListTokens] = useState([])
    const [loading, setLoading] = useState(true)
    const [visualMenu, setVisualMenu] = useState('clientes')
    const [buttonEnviarNoti, setButtonEnviarNoti] = useState([])
    const [titulo, setTitulo] = useState("")
    const [message, setMessage] = useState("")


    useEffect(()=> {
        async function loads() {
            const {data: user}= await apiAgendaNaMao.get('users')
            setUsers(user)
            const {data: tokens} = await apiAgendaNaMao.get('notificationAgendamentoApp')
            setListTokens(tokens)
            setLoading(false)
        }
        loads()
    },[])
    
    //Dados para tabela de clientes
    const formatData = users.map(i => ({
        id: i.id,
        name: i.name,
        estabelecimento: i.estabelecimento,
        data: moment(i.createdAt).format('YYYY-MM-DD'),
        tel: i.tel,
        status: i.status,
        plano: i.plano,
    }))

    //Dados para tabela de notifications
    const formatDataNotifications = listTokens.map(i => ({
        id: i.id,
        name: i.Collaborator.name,
        tel: i.Collaborator.tel,
        token: i.token,
    }))

    //Colunas para tabela de notifications
    const columnsNotifications =  useMemo(() => [
        
        {
            field: 'name', headerName: 'Nome', width: 250 
        },
        {
            field: 'tel', headerName: 'Tel', width: 130 
        },
        {
            field: 'token', headerName: 'Token', width: 390 
        },
        {
            field: 'enviar', 
            headerName: 'Enviar notificação', 
            width: 190, 
            type: 'actions',
            renderCell: paramns => <ModalNotifications itens={paramns}/>
        },
    ], [])

    //Colunas para tabela de clientes
    const columns =  useMemo(() => [
        
            {
                field: 'name', headerName: 'Nome', width: 250 
            },
            {
                field: 'estabelecimento', headerName: 'Estabelecimento', width: 250 
            },
            {
                field: 'tel', headerName: 'Tel', width: 130 
            },
            {
                field: 'status', headerName: 'Status', width: 130,
                cellClassName: (params) => {
    
                    if (params.value == null) {
                      return '';
                    }
              
                    return clsx('super-app', {
                      negative: params.value === 'Desativado',
                      positive: params.value === 'Ativado',
                    });
                  },
            },
            {
                field: 'plano', headerName: 'Plano', width: 130 
            },
            {
                field: 'data', headerName: 'Data criação', width: 100 
            },
            {
                field: 'created', headerName: 'Dias criação', width: 100,
                renderCell: paramns => moment().diff(paramns.row.data, 'days')

            },
            {
                field: 'edite', 
                headerName: 'Edite', 
                width: 120, 
                type: 'actions',
                renderCell: paramns => <ModalGestao itens={paramns}/>
            },
    ], [])

    const paginationModel = { page: 0, pageSize: 10 };

   async function submitNotificartions(params) {

        if(buttonEnviarNoti.length > 0 && titulo && message){
        
            try{

                await apiAgendaNaMao.post('notificationGestao',{
                    token: buttonEnviarNoti,
                    message: message,
                    titulo: titulo
                })
                
                alert('Mensagem enviada')
                setMessage("")
                setTitulo("")
                return
            }catch(er){
                alert('error ao enviar notificação')
            }
 
            return
        }

        
    }
    return(
        <Container>
            <h1 style={{color: 'white'}}>Gestão sistema</h1>
            <ContainerMain01>
               <ContainerMenu>
                  
                    <button onClick={() => setVisualMenu('clientes')}>
                        <GroupsIcon /> Clientes
                    </button>
                
                    <button onClick={() => setVisualMenu('whats')}>
                        <WhatsAppIcon/> WhatsApp
                    </button>
                 
                    <button onClick={() => setVisualMenu('notifications')}>
                        <NotificationsActiveIcon/>  Notificações
                    </button>
                   
               </ContainerMenu>
                
               <ContainerTable>
                    {visualMenu === 'clientes' && 
                        <Paper sx={{ width: '100%' }} elevation={3}>
                        
                            <DataGrid
                                disableRowSelectionOnClick
                                loading={loading}
                                editMode="row"
                                rows={formatData}
                                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                columns={columns}
                                initialState={{ pagination: { paginationModel } }}
                                pageSizeOptions={[5, 10, 20, 30, 50, 70, 100 ]}
                                checkboxSelection
                                sx={{ border: 0, '& .super-app.negative': {
                                    backgroundColor: '#d47483',
                                    color: '#1a3e72',
                                    fontWeight: '600',
                                    },
                                    '& .super-app.positive': {
                                    backgroundColor: 'rgba(157, 255, 118, 0.49)',
                                    color: '#1a3e72',
                                    fontWeight: '600',
                                    },
                                }}
                                getRowId={row => row.id}
                            />
                        
                        </Paper>
                    }
                    
                    {visualMenu === 'notifications' &&
                    
                       
                        <Paper sx={{ width: '100%' }} elevation={3}>
                                                
                            <DataGrid
                                disableRowSelectionOnClick
                                loading={loading}
                                editMode="row"
                                onRowSelectionModelChange={(i) => setButtonEnviarNoti(i)}
                                rows={formatDataNotifications}
                                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                columns={columnsNotifications}
                                initialState={{ pagination: { paginationModel } }}
                                pageSizeOptions={[5, 10, 20, 30, 50, 70, 100 ]}
                                checkboxSelection
                                sx={{ border: 0,
                                }}
                                getRowId={row => row.token}
                            />

                        </Paper>
                     
                        
                        
                    }
               </ContainerTable>
               {visualMenu === 'notifications' &&
                    <>
                        {buttonEnviarNoti.length > 0 && buttonEnviarNoti.length <= 90 && 
                            <>
                            <p>Qtde de contas selecionadas: {buttonEnviarNoti.length}</p>
                            <p>OBS: só é permitido enviar 90 mensagens de uma única vez.</p>
                            <div className="divButtonNotificationGrupo">
                                <p>Titulo:</p>
                                <input onChange={(i) => setTitulo(i.target.value)} />
                                <p>Mensagem:</p>
                                <textarea onChange={(i) => setMessage(i.target.value)}/>
                                <button onClick={submitNotificartions}>Enviar notificação ao grupo</button>
                            </div>
                            </>
                        }
                        </>
                    }
            </ContainerMain01>
        </Container>
    )
}